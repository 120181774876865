
import {
  RevIllustration,
  RevIllustrationSource,
} from '@backmarket/design-system'

import { mobileSmallThreshold } from './ImageRender.constant'

export default {
  components: {
    RevIllustration,
    RevIllustrationSource,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    srcMobile: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    widthMobile: {
      type: Number,
      default: undefined,
    },
    heightMobile: {
      type: Number,
      default: undefined,
    },
    mobileUpperThreshold: {
      type: String,
      default: mobileSmallThreshold,
    },
    loading: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    desktopWidth() {
      return this.width / 2
    },
    desktopHeight() {
      return this.height / 2
    },
  },
}
