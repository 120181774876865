
import { RevTag } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'

import { tw } from '@core/tailwind'

import { COLOR } from '../../helpers/constants'

export default {
  components: {
    RevTag,
  },
  props: {
    badge: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: COLOR.BLACK,
      validator: (color) => Object.keys(COLOR).includes(color),
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasBadge() {
      return !isEmpty(this.badge)
    },
    badgeStyle() {
      return tw`pr-5 ${COLOR[this.textColor]} title-1`
    },
  },
}
