// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3QF3MwKnDADGo8y1aHLWj5{height:calc(100% - 20rem)}._1h9gn7U128PGJNoxfTmmwS{background:linear-gradient(66.46deg,#111 -22.21%,rgba(17,17,17,0) 72.02%)}@media (min-width:992px){._3QF3MwKnDADGo8y1aHLWj5{height:calc(100% - 30rem)}._1h9gn7U128PGJNoxfTmmwS{background:linear-gradient(90.83deg,#111 -36.16%,rgba(17,17,17,0) 77.91%)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3QF3MwKnDADGo8y1aHLWj5",
	"gradientOverlay": "_1h9gn7U128PGJNoxfTmmwS"
};
module.exports = ___CSS_LOADER_EXPORT___;
