
import { RevBreadcrumb } from '@backmarket/design-system'

import { ROUTES } from '@router'

import translations from './Breadcrumb.translations'

export default {
  components: {
    RevBreadcrumb,
  },
  props: {
    currentPageTitle: {
      type: String,
      required: true,
    },
    intermediaryPath: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    translations: () => translations,
    breads() {
      return [
        {
          title: this.$t(translations.home),
          slug: { name: ROUTES.HOME },
        },
        ...this.intermediaryPath,
        {
          title: this.currentPageTitle,
          slug: this.$route.path,
        },
      ]
    },
  },
  methods: {
    clickBreadcrumb(value) {
      this.$emit('click-breadcrumb', value)
    },
  },
}
