
// Full width image with a title and eventually a badge above.
import { RevContainer } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

// TODO: Remove component imports from COMPONENT_FROM_CONTENT_TYPE, as they
// introduce a circular dependency.
// eslint-disable-next-line import/no-cycle
import { COLOR } from '../../helpers/constants'
import { imageValidator } from '../../helpers/image'
import ImageRender from '../private/ImageRender'

export default {
  components: {
    RevContainer,
    ImageRender,
  },
  props: {
    image: {
      type: Object,
      validator: imageValidator,
      default: () => ({}),
    },
    textColor: {
      type: String,
      required: true,
      validator: (color) => Object.keys(COLOR).includes(color),
    },
  },
  computed: {
    ...mapGetters({
      breakpoint: 'breakpoint',
    }),
    hasGradient() {
      return this.textColor === 'white'
    },
    hasImage() {
      return !isEmpty(this.image)
    },
  },
}
